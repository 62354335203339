import React, { useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import {
  handleSignout,
  toCapitalize,
  UserAccountType,
} from "../../../../utils/Utils";
import { Storage } from "../../../../services/storage/storage";
import { Redirect } from "react-router-dom";

const User = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const user = Storage.getLoggedInUser();
  const switchToPermission = () => {
    let data = user;
    if (user.accountType === UserAccountType.admin) {
      data.accountType = UserAccountType.staff;
      data.isAdmin = true;
    } else if (user.accountType === UserAccountType.manager) {
      data.accountType = UserAccountType.staff;
      data.isManager = true;
    } else if (
      user.accountType === UserAccountType.staff &&
      (data.isManager || data.isAdmin)
    ) {
      data.accountType = data.isAdmin
        ? UserAccountType.admin
        : UserAccountType.manager;
    }
    Storage.setLoggedInUser(data);
    <Redirect to={`${process.env.PUBLIC_URL}`} />;
    toggle();
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar
            icon="user-alt"
            className="sm"
            image={user?.avatar?.thumbUrl}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <UserAvatar icon="user-alt" image={user?.avatar?.thumbUrl} />
            <div className="user-info">
              <span className="lead-text">
                {(user.firstName ?? "") + " " + (user?.lastName ?? "")}
              </span>
              <span className="sub-text">{user?.email ?? ""}</span>
              <span className="sub-text">
                {toCapitalize(user?.accountType ?? "")}
              </span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              className={"line-height-18"}
              link={"/user-certificate"}
              icon="setting-alt"
              onClick={toggle}
            >
              My Certificate
            </LinkItem>
            <LinkItem
              className={"line-height-18"}
              link={"/pending-certificate"}
              icon="clock"
              onClick={toggle}
            >
              Pending Certificate
            </LinkItem>
            <LinkItem
              className={"line-height-18"}
              link={"/user-archive-certificate"}
              icon="reports-alt"
              onClick={toggle}
            >
              Archive Certificate
            </LinkItem>
            <LinkItem
              className={"line-height-18"}
              link={"/check-password"}
              icon="unlock-fill"
              onClick={toggle}
            >
              Change Password
            </LinkItem>
            {user.accountType !== UserAccountType.staff && (
              <LinkItem
                className={"line-height-18 pt-1"}
                icon="target"
                to={`${process.env.PUBLIC_URL}`}
                onClick={switchToPermission}
              >
                View as Learner
              </LinkItem>
            )}
            {user.accountType === UserAccountType.staff &&
              (user.isAdmin || user.isManager) && (
                <LinkItem
                  icon="target"
                  to={`${process.env.PUBLIC_URL}`}
                  onClick={switchToPermission}
                >
                  {user.isAdmin ? "View as Admin" : "View as Manager"}
                </LinkItem>
              )}
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a
              href={`${process.env.PUBLIC_URL}/auth-login`}
              onClick={handleSignout}
            >
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
