import moment from "moment";
import { Redirect } from "react-router-dom";
import { Storage } from "../services/storage/storage";
import { toast } from "react-toastify";
import { Icon } from "../components/Component";
import { AppText } from "./AppText";
import React from "react";

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

// Logs out user
export const handleSignout = () => {
  Storage.clearAll();
};

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();
  reverse
    ? (date = m + " - " + d + " - " + y)
    : (date = y + " - " + d + " - " + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

  return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

export const truncate = (str, n) => {
  return str.length > n
    ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n)
    : str;
};

export const RedirectAs404 = ({ location }) => (
  <Redirect to={Object.assign({}, location, { state: { is404: true } })} />
);

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// Returns Currency based value for invest panel
export const returnCurrency = (currency, data, upperCase) => {
  if (currency === "usd") {
    return { value: data.usd.toFixed(2), label: upperCase ? "USD" : "$" };
  } else if (currency === "eur") {
    return { value: data.euro.toFixed(2), label: upperCase ? "EUR" : "euro" };
  } else if (currency === "btc") {
    return { value: data.BTC.toFixed(6), label: "BTC" };
  } else {
    return { value: data.ETH.toFixed(2), label: "ETH" };
  }
};

// Returns levels
export const returnLevel = (currency, data, upperCase) => {
  if (currency === "usd") {
    return data.usd;
  } else if (currency === "eur") {
    return data.euro;
  } else if (currency === "btc") {
    let amount = data.BTC.map((item) => {
      return item.toFixed(6);
    });
    return amount;
  } else {
    return data.ETH;
  }
};

export const PASSWORD_TOOL_TIP = (
  <p className="text-start">
    Password must contain:
    <br />• One capital letter.
    <br />• One lowercase letter.
    <br />• One digit.
    <br />• One special character (e.g. !@#$%&*)
    <br />• at least 8 characters.
  </p>
);

export const UserAccountType = {
  admin: "ADMIN",
  manager: "MANAGER",
  staff: "STAFF",
};

export const AuthPaths = [
  "/auth-reset",
  "/auth-login",
  "/auth-create-password",
  "/confirmation",
  "/reset-password",
];

export const AdminPaths = [
  "/",
  "/staff",
  "/staff-training",
  "/manage-face-to-face",
  "/face-to-face-detail",
  // "/policies",
  // "/add-policy",
  "/resources",
  "/roles-list",
  "/role",
  "/add-staff",
  "/matrix",
  "/training-list",
  "/course-wizard",
  "/training-wizard",
  "/certificate-list",
  "/location",
  "/check-password",
  "/change-password",
  // "/policy-list",
  // "/policy-view",
  "/user-certificate",
  "/pending-certificate",
  "/user-archive-certificate",

  "/training-assesment-result",
  "/resource-folders",
  "/cpd-list",
  "/support",
  "/add-ticket",
  "/add-resources",
  "/privacy",
  "/help",
  "/terms-condition",
  "/evalution-form",
];

export const ManagerPaths = [
  "/",
  "/staff",
  "/staff-training",
  "/add-staff",
  "/manage-face-to-face",
  "/face-to-face-detail",
  // "/policies",
  "/resources",
  "/roles-list",
  "/matrix",
  "/training-list",
  "/certificate-list",
  "/location",
  "/check-password",
  "/change-password",
  "/training-wizard",
  // "/policy-list",
  // "/policy-view",
  "/user-certificate",
  "/pending-certificate",
  "/user-archive-certificate",
  "/resource-folders",
  "/support",
  "/add-ticket",
  "/privacy",
  "/help",
  "/terms-condition",
  "/evalution-form",
];

export const LearnerPaths = [
  "/",
  "/resources",
  "/learner-face-to-face",
  // "/learner-policies",
  // "/policy-view",
  "/view-all-course",
  "/start-course",
  "/check-password",
  "/change-password",
  "/start-course-details",
  "/evalution-form",
  "/course-assesment-test",
  "/training-certificate",
  "/user-certificate",
  "/pending-certificate",
  "/user-archive-certificate",
  "/training-assesment-result",
  "/resource-folders",
  "/cpd-list",
  "/face-to-face-detail",
  "/privacy",
  "/help",
  "/terms-condition",
];

export const CourseStatus = {
  COMPLETE: { label: "COMPLETED", value: "Complete" },
  DUESOON: { label: "DUE_SOON", value: "Due soon" },
  OVERDUE: { label: "OVERDUE", value: "Overdue" },
  ASIGN_BUT_NOT_COMPLETE: {
    label: "ASSIGN_NOT_COMPLETED",
    value: "Assign, but not complete",
  },
};

export const FaceToFaceDuration = [
  { label: "30 mins", value: 30 },
  { label: "1:00 hour", value: 60 },
  { label: "1:30 hours", value: 90 },
  { label: "2:00 hours", value: 120 },
  { label: "2:30 hours", value: 150 },
  { label: "3:00 hours", value: 180 },
  { label: "3:30 hours", value: 210 },
  { label: "4:00 hours", value: 240 },
  { label: "4:30 hours", value: 270 },
  { label: "5:00 hours", value: 300 },
  { label: "5:30 hours", value: 330 },
  { label: "6:00 hours", value: 360 },
];

export const ColorsArray = [
  "primary",
  "secondary",
  "warning",
  "danger",
  "success",
  "info",
  "azure",
  "blue",
  "pink",
  "indigo",
  "dark",
  "gray",
  "orange",
  "teal",
  "purple",
];

export const FDUDuration = [
  { label: "30 mins", value: 30 },
  { label: "1:00 hour", value: 60 },
  { label: "1:30 hours", value: 90 },
  { label: "2:00 hours", value: 120 },
  { label: "2:30 hours", value: 150 },
  { label: "3:00 hours", value: 180 },
  { label: "3:30 hours", value: 210 },
  { label: "4:00 hours", value: 240 },
  { label: "4:30 hours", value: 270 },
  { label: "5:00 hours", value: 300 },
  { label: "5:30 hours", value: 330 },
  { label: "6:00 hours", value: 360 },
  { label: "6:30 hours", value: 390 },
  { label: "7:00 hours", value: 420 },
  { label: "7:30 hours", value: 450 },
  { label: "8:00 hours", value: 480 },
  { label: "8:30 hours", value: 510 },
  { label: "9:00 hours", value: 540 },
  { label: "9:30 hours", value: 570 },
  { label: "10:00 hours", value: 600 },
];

export const TrainingType = [
  { label: "F2F", value: "F2F" },
  { label: "E-Learning", value: "E-LEARNING" },
  { label: "Zoom", value: "ZOOM" },
  { label: "Competency", value: "COMPETENCY" },
];

export const dueDateColor = (dueInStart, maxDueAt) => {
  if (dueInStart && maxDueAt) {
    let reciveDate = moment(removeTimeFromServerDateString(maxDueAt));
    let dueDate = moment(removeTimeFromServerDateString(dueInStart));
    let currentDate = moment();
    var color = "danger";
    if (reciveDate < currentDate) {
      color = "danger";
    } else if (dueDate <= currentDate) {
      color = "warning";
    } else {
      color = "success";
    }
    return color;
  } else {
    return "danger";
  }
};

export const removeTimeFromServerDateString = (dateString) => {
  let date = dateString?.replace("T00:00:00.000Z", "") ?? "";
  return date;
};

export const dateToShowFormate = (date) => {
  let dueDate = moment(removeTimeFromServerDateString(date), "YYYY-MM-DD");
  return dueDate.format("DD-MM-YYYY");
};

export const isNumberOnly = (value) => {
  return !isNaN(+value);
};

export const toCapitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const openLinkInNewTab = (url, event) => {
  event.preventDefault();
  if (url === null || url === undefined) {
    return;
  }
  if (event.metaKey) {
    window.open(url, "_blank");
  } else {
    window.open(url, "_blank");
  }
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const listAsString = (list) => {
  if ((list?.length ?? 0) === 0) {
    return "";
  }
  return list.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

export const showErrorToast = (message) => {
  let text = message?.length > 0 ? message : AppText.connectError;
  toast.error(text, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <CloseButton />,
  });
};

export const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <CloseButton />,
  });
};
const CloseButton = () => {
  return (
    <span className="btn-trigger toast-close-button" role="button">
      <Icon name="cross"></Icon>
    </span>
  );
};

export function newlineText(text) {
  const newText =
    text?.split("\n")?.map((str, index) => (
      <React.Fragment key={index}>
        {str}
        <br />
      </React.Fragment>
    )) ?? "";
  return newText;
}

export function dueDate(frequency, date) {
  let month = frequencyToMonth(frequency);
  var oldDate = new Date(date);
  var result = new Date(oldDate.setMonth(oldDate.getMonth() + month));
  return result;
}

export function completedDate(frequency, date) {
  let month = frequencyToMonth(frequency);
  var dueDate = new Date(date);
  var result = new Date(dueDate.setMonth(dueDate.getMonth() - month));
  return result;
}

export function frequencyToMonth(frequency) {
  let month = 0;
  switch (frequency) {
    case "ONE_OFF":
      month = 0;
      break;
    case "EVERY_SIX_MONTHS":
      month = 6;
      break;
    case "EVERY_YEAR":
      month = 12;
      break;
    case "EVERY_TWO_YEARS":
      month = 24;
      break;
    case "EVERY_THREE_YEARS":
      month = 36;
      break;
    default:
      break;
  }
  return month;
}

export const RadioOptionTypes = {
  role: "role",
};

export function runWithRetry(fn, opts) {
  const options = {
    retry: opts?.retry ?? 3,
    interval: opts?.interval ?? 500,
  };
  const context = {
    tries: 0,
    timer: undefined,
  };

  context.timer = setInterval(() => {
    if (context.tries > options.retry) {
      clearInterval(context.timer);
    }

    const succeeded = fn();
    if (succeeded) {
      clearInterval(context.timer);
    } else {
      context.tries++;
    }
  }, options.interval);
}
